<template>
  <CTabs variant="tabs" class="nav-tabs-boxed">
    <CTab title="Motivos atendimento"> <MotivosAtendimento /> </CTab>
  </CTabs>
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import MotivosAtendimento from "./MotivosAtendimento.vue";

export default {
  name: "agendas",
  components: {
    CrudBase,
    MotivosAtendimento,
  },
  data() {
    return {};
  },
};
</script>
