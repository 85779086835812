<template>
  <crud-base
    :url="url"
    :columns="columns"
    :onDelete="() => {}"
    :onUpdate="() => {}"
    :onStore="() => {}"
    :enableSearch="true"
    :formInputs="formInputs"
    primaryKey="id"
  />
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
export default {
  name: "motivos-atendimento",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/agenda/motivos",
      columns: [
        { label: "ID", field: "id", sortable: false },
        { label: "ATIVO", field: "ativo", sortable: false },
        { label: "DESCRICAO", field: "descricao", sortable: false },
        { label: "TIPO", field: "tipo_motivo", sortable: false },
        { label: "TIPO AGENDA", field: "tipo_agenda", sortable: false },
        { label: "ID EXTERNO", field: "id_externo", sortable: false },
      ],
      formInputs: {
        ativo: {
          type: "string",
          ui: {
            columns: 4,
            label: "Ativo",
            widget: "radio",
            widgetConfig: {
              enumSource: [
                {
                  label: "Sim",
                  value: 1,
                },
                {
                  label: "Não",
                  value: 0,
                },
              ],
            },
          },
          rules: {
            required: {
              value: true,
              errMsg: "Campo de preenchimento obrigatório",
            },
          },
        },
        tipo_agenda: {
          type: "string",
          ui: {
            columns: 4,
            label: "Tipo de Agenda",
            widget: "radio",
            widgetConfig: {
              enumSource: [
                {
                  label: "Atendimento",
                  value: "A",
                },
                {
                  label: "Cobrança",
                  value: "C",
                },
              ],
            },
          },
          rules: {
            required: {
              value: true,
              errMsg: "Campo de preenchimento obrigatório",
            },
          },
        },
        tipo_motivo: {
          type: "string",
          ui: {
            columns: 4,
            label: "Tipo",
            widget: "radio",
            widgetConfig: {
              enumSource: [
                {
                  label: "Vendido",
                  value: "VENDIDO",
                },
                {
                  label: "Atendimento",
                  value: "ATENDIMENTO",
                },
                {
                  label: "Em aberto",
                  value: "ABERTO",
                },
                {
                  label: "Perdido",
                  value: "PERDIDO",
                },
              ],
            },
          },
          rules: {
            required: {
              value: true,
              errMsg: "Campo de preenchimento obrigatório",
            },
          },
        },
        descricao: {
          type: "string",
          ui: {
            columns: 6,
            label: "Descrição",
          },
          rules: {
            required: {
              value: true,
              errMsg: "Campo de preenchimento obrigatório",
            },
          },
        },
        id_externo: {
          type: "string",
          ui: {
            columns: 6,
            label: "ID Externo",
          },
          rules: {
            required: false,
          },
        },
      },
    };
  },
};
</script>
